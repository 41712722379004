import React from 'react';
import { OpenPositionPreviewType } from '../../utils/careersTypes';
import ArrowRight from '../icons/ArrowRight';
import Link from '../Link';
import Text from '../Text';
import './style.scss';

export interface OpenPositionPreviewProps extends OpenPositionPreviewType {
  className?: string;
}

const OpenPositionPreview: React.FC<OpenPositionPreviewProps> = ({
  title,
  conditions,
  slug: { current: slug },
}) => {
  return (
    <Link href={`/careers/${slug}`}>
      <div className="position-preview">
        <div className="preview-content">
          <div>
            <Text type="p" className="preview-content__title" weight="bold">
              {title}
            </Text>
            <Text type="p">{conditions}</Text>
          </div>
          <ArrowRight className="preview-content__arrow position-preview__arrow" />
        </div>
        <div className="position-preview__border" />
        <div className="position-preview__underline" />
      </div>
    </Link>
  );
};

export default React.memo(OpenPositionPreview);
