import { HeadProps, graphql } from 'gatsby';
import { LocaleNode } from 'gatsby-plugin-react-i18next/dist/types';
import React from 'react';
import CareersPage from '../../components/CareersPage';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { CareersPageType } from '../../utils/careersTypes';

type DataProps = {
  sanityCareersPage: CareersPageType;
  locales: { edges: Array<{ node: LocaleNode }> };
};

const Careers: React.FC<HeadProps<DataProps>> = ({
  data: { sanityCareersPage },
}) => {
  return (
    <Layout>
      <Navigation headerBackgroundColor="none" />

      <CareersPage {...sanityCareersPage} />

      <Footer noFooterCard />
    </Layout>
  );
};

export const query = graphql`
  query CareersInfo($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sanityCareersPage {
      headerImage {
        caption
        mainImage {
          asset {
            gatsbyImageData(
              height: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      missionText
      missionMedia {
        altText
        videoUrl
        colspan
        rowspan
        image {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              width: 400
              height: 400
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      teamMedia {
        altText
        videoUrl
        colspan
        rowspan
        image {
          asset {
            gatsbyImageData(
              height: 400
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      openPositions {
        title
        conditions
        slug {
          current
        }
      }
      recruitmentStep {
        title
        body
      }
      benefits {
        icon
        title
        description
        body
        outlineColor
      }
    }
  }
`;

export const Head = ({ data }: HeadProps<DataProps>) => {
  // TODO: replace with useTranslation once Context is available in Gatsby Head API
  // https://github.com/gatsbyjs/gatsby/issues/36458#issuecomment-1816040142
  // https://github.com/gatsbyjs/gatsby/discussions/35841#discussioncomment-3258990

  const dataLanguage = data.locales.edges.find(
    (e) => e.node.ns === 'translation'
  )!.node.data;

  const t: Record<string, string> = JSON.parse(dataLanguage);

  return (
    <SEO
      title={t['careers_meta_title']}
      description={t['careers_meta_description']}
    />
  );
};

export default React.memo(Careers);
