import React, { CSSProperties, useMemo } from 'react';
import { BenefitType } from '../../utils/careersTypes';
import Markdown from '../Markdown';
import './style.scss';
import Text from '../Text';

interface BenefitProps extends BenefitType {
  className?: string;
}

const Benefit: React.FC<BenefitProps> = ({
  className,
  description,
  title,
  icon,
  outlineColor,
}) => {
  const containerStyle = useMemo<CSSProperties>(
    () => ({ borderColor: outlineColor }),
    [outlineColor]
  );

  const iconHtml = useMemo(() => ({ __html: icon }), [icon]);

  return (
    <div style={containerStyle} className={`benefit-item ${className}`}>
      <div className="benefit-item__icon" dangerouslySetInnerHTML={iconHtml} />
      <Text type="h6" weight="bold" className="benefit-item__title">
        {title}
      </Text>
      <Markdown source={description} />
    </div>
  );
};

export default React.memo(Benefit);
