import React, { useCallback } from 'react';
import { BenefitType } from '../../utils/careersTypes';
import Benefit from '../Benefit';
import './style.scss';

type BenefitsListProps = {
  benefits: BenefitType[];
};

const BenefitsList: React.FC<BenefitsListProps> = ({ benefits }) => {
  const renderBenefit = useCallback<(benefit: BenefitType) => JSX.Element>(
    benefit => {
      return <Benefit {...benefit} />;
    },
    []
  );

  return <div className="benefits">{benefits?.map(renderBenefit)}</div>;
};

export default React.memo(BenefitsList);
