import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { BgImage } from 'gbimage-bridge';
import React, { useCallback, useMemo } from 'react';
import {
  CareersPageType,
  OpenPositionPreviewType,
} from '../../utils/careersTypes';
import BenefitsList from '../BenefitsList';
import JobForm from '../JobForm';
import Markdown from '../Markdown';
import MasonryBlock from '../MasonryBlock';
import OpenPositionPreview from '../OpenPositionPreview';
import { SourceOption } from '../Picker';
import RecruitmentSteps from '../RecruitmentSteps';
import Text from '../Text';
import './style.scss';

const CareersPage: React.FC<CareersPageType> = ({
  headerImage,
  openPositions,
  recruitmentStep,
  missionMedia,
  missionText,
  teamMedia,
  benefits,
}) => {
  const { t } = useTranslation();

  const image = getImage(headerImage?.mainImage?.asset);

  const renderPositionPreview = useCallback<
    (position: OpenPositionPreviewType) => JSX.Element
  >((position) => {
    return <OpenPositionPreview {...position} />;
  }, []);

  const formJobOptions = useMemo<SourceOption[]>(
    () =>
      openPositions?.map((position) => ({
        value: position.slug.current,
        body: position.title,
      })),
    [openPositions]
  );

  return (
    <div className="careers-page">
      <BgImage image={image}>
        <Text className="careers-page__title" type="h1" weight="bold">
          {headerImage?.caption}
        </Text>
      </BgImage>

      <div className="careers-page__body">
        <div className="mission-block careers-page__item">
          <MasonryBlock
            height={2}
            width={2}
            masonryItems={missionMedia}
            className="mission-block__masonry"
          />

          <Markdown
            className="mission-block__md-wrapper"
            source={missionText}
          />
        </div>

        <div className="careers-page__item">
          <Text type="h4" className="careers-page__header" weight="bold">
            {t('positions_list_title')}
          </Text>
          {openPositions?.map(renderPositionPreview)}
        </div>

        <Text weight="bold" type="h4" className="careers-page__header">
          {t('team_media_title')}
        </Text>
        <MasonryBlock
          className="careers-page__item"
          height={4}
          width={4}
          masonryItems={teamMedia}
        />

        <Text weight="bold" type="h4" className="careers-page__header">
          {t('benefits_title')}
        </Text>
        <BenefitsList benefits={benefits} />

        <Text weight="bold" type="h4" className="careers-page__header">
          {t('recruitment_steps_title')}
        </Text>
        <RecruitmentSteps
          steps={recruitmentStep}
          className="careers-page__item"
        />
      </div>

      <div className="job-form-wrapper">
        <JobForm positions={formJobOptions} />
      </div>
    </div>
  );
};

export default React.memo(CareersPage);
